$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// set if you're also including font-awesome
// $fa-font-path: "~font-awesome/fonts";

//@import '~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';
//@import "~font-awesome/css/font-awesome.min.css";
@import '~bootstrap-sass/assets/stylesheets/bootstrap';

@import '~ui-select/dist/select';
@import '~toastr/toastr.scss';
/*
@import '~angular-xeditable/dist/css/xeditable.min.css';
@import '~select2/src/scss/core.scss';
@import '~select2-bootstrap-theme/src/select2-bootstrap.scss';*/

/*@import '~angular-ui-tree/dist/angular-ui-tree.min.css';
@import "tggleswitch";
@import '/partial/upload-button';*/

footer {
  background-color: #efefef;
  padding: 1em 0 0;
}
